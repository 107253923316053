




import { cva } from "class-variance-authority";
import * as React from "react";
import { cls } from "~/old-app/utils/helpers/cls";

// export type InputProps = Omit<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   "size"
// > &
//   VariantProps<typeof inputVariants> &
//   Partial<Omit<InputMaskProps, "size">> & {
//     className?: string;
//     inputClassName?: string;
//     end?: React.ReactNode;
//     start?: React.ReactNode;
//     error?: string;
//     isPhoneInput?: boolean;
//   };

export const inputVariants = cva(
  `border text-3xs border-primary-300 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-400 focus:shadow-outline focus:ring-primary-400 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-700/50 h-10`,
  {
    variants: {
      size: {
        default: "  text-md",
        sm: "h-8  text-xs placeholder:text-xs",
        md: "h-form-md  text-md placeholder:text-md",
        lg: "h-form-lg  text-lg placeholder:text-lg",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);



export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, forwardedRef) => {
    const ref = React.useRef<HTMLInputElement>(null)

    React.useImperativeHandle(
      forwardedRef,
      () => ref.current as HTMLInputElement,
    )

    return (
      <input
        type={type}
        className={cls(
          'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input };

